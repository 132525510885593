import React from "react";

const MiloLogo = () => (
  <svg width="71" height="28" viewBox="0 0 71 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32.1437 10.3257C32.2444 9.45882 32.7311 8.59195 33.5031 8.59195C34.2751 8.59195 35.1814 10.1003 35.7016 10.8805C37.46 13.896 38.5224 17.2894 38.8064 20.7975C39.0078 23.6235 38.3365 27.091 35.0303 27.3164C33.8791 27.4386 32.7666 26.847 32.1941 25.8081C30.3647 22.6006 32.1101 10.5337 32.1437 10.3257Z" fill="black"/>
  <path d="M35.8694 2.80113C36.0266 3.91653 35.5893 5.03282 34.7255 5.72143C33.8617 6.41003 32.7056 6.56393 31.701 6.12402C30.6965 5.68412 29.9996 4.71879 29.878 3.59866C29.7208 2.48326 30.158 1.36697 31.0218 0.678363C31.8856 -0.0102412 33.0417 -0.164134 34.0463 0.275769C35.0509 0.715672 35.7477 1.681 35.8694 2.80113Z" fill="black"/>
  <path d="M70.2401 17.5728C70.2401 30.5759 52.031 30.5759 52.031 17.5728C52.031 4.56972 70.2401 4.56972 70.2401 17.5728Z" fill="black"/>
  <path d="M50.2521 27.0737C38.1854 30.4198 39.8469 1.24085 44.8145 0.00989032C47.1305 -0.562246 49.9164 23.8662 50.2521 27.0737Z" fill="black"/>
  <path d="M4.73772 0.859469C5.17139 0.384298 5.86044 0.270406 6.41598 0.582069C8.74876 1.72634 12.7095 8.80003 14.2535 16.3418C14.2535 16.0644 16.7876 2.19445 19.2882 0.547394C20.7315 -0.440841 22.3762 2.02108 22.9804 3.04399C28.1327 11.9034 29.7942 24.0917 27.4949 26.5709C27.104 27.0029 26.557 27.2478 25.9845 27.2471C21.9063 27.2471 20.7819 15.1109 20.9497 12.2502C20.6141 14.6254 18.0631 27.3338 14.3709 27.3338C10.6788 27.3338 7.77537 15.1976 7.50685 12.2502C7.69146 14.7988 6.7852 27.2471 2.47207 27.2471C0.961632 27.2471 0.525284 25.4093 0.391023 24.1784C-0.196368 18.4397 2.37137 3.7895 4.73772 0.859469Z" fill="black"/>
  </svg>

);

export default MiloLogo;

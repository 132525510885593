import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql, Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Button from "components/_ui/Button";
import About from "components/About";
import Layout from "components/Layout";
import ProjectCard from "components/ProjectCard";
// import { ReactComponent as MiloLogo } from '../components/_ui/milo.svg'
import MiloLogo from 'components/_ui/MiloLogo'
import FluxLogo from 'components/_ui/FluxLogo'
import DeepserveIcon from 'components/_ui/DeepserveIcon'
import BranchLogo from 'components/_ui/BranchLogo'
import TNLogo from 'components/_ui/TNLogo'
import JeffWells from 'images/jeff-wells.png'

const Hero = styled("div")`
    padding-top: 2.5em;
    padding-bottom: 3em;
    margin-bottom: 6em;
    max-width: 830px;

    @media(max-width:${dimensions.maxwidthMobile}px) {
       margin-bottom: 3em;
    }

    h1 {
        margin-bottom: 1em;

        a {
            text-decoration: none;
            transition: all 100ms ease-in-out;

            &:nth-of-type(1) { color: ${colors.blue500}; }
            &:nth-of-type(2) { color: ${colors.orange500}; }
            &:nth-of-type(3) { color: ${colors.purple500}; }
            &:nth-of-type(4) { color: ${colors.green500}; }
            &:nth-of-type(5) { color: ${colors.teal500}; }

            &:hover {
                cursor: pointer;
                transition: all 100ms ease-in-out;

                &:nth-of-type(1) { color: ${colors.blue600};    background-color: ${colors.blue200};}
                &:nth-of-type(2) { color: ${colors.orange600};  background-color: ${colors.orange200};}
                &:nth-of-type(3) { color: ${colors.purple600};  background-color: ${colors.purple200};}
                &:nth-of-type(4) { color: ${colors.green600};   background-color: ${colors.green200};}
                &:nth-of-type(5) { color: ${colors.teal600};    background-color: ${colors.teal200};}

            }
        }
    }
`

const Section = styled("div")`
    margin-bottom: 10em;
    display: flex;
    flex-direction: column;

    @media(max-width:${dimensions.maxwidthTablet}px) {
        margin-bottom: 4em;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
`

const WorkAction = styled(Link)`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;
    margin-left: auto;

    @media(max-width:${dimensions.maxwidthTablet}px) {
       margin: 0 auto;
    }

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }

    &:hover {
        color: ${colors.blue500};
        transition: all 150ms ease-in-out;

        span {
            transform: translateX(0px);
            opacity: 1;
            transition: transform 150ms ease-in-out;
        }
    }
`

const MiloLogoLink = () => {

  const ATag = styled.a`
    position: relative;
    bottom: -2px;
    &:hover {
      svg path {
        color: yellow;
      }
    }
  `

  return (
    <ATag target='_blank' href='https://getmilo.com'>
      <MiloLogo />
    </ATag>
  )
}

const DeepserveIconLink = () => {

  const ATag = styled.a`
  position: relative;
  bottom: -8px;


    &:hover {
      svg {
        fill: '#333' !important;
      }
    }
  `
  return (
    <ATag target='_blank' href='https://www.deepserve.ai'>
      <DeepserveIcon />
    </ATag>
  )
}

const BranchLogoLink = () => {

  const ATag = styled.a`
  position: relative;
  bottom: -1px;
  `
  return (
    <ATag target='_blank' href='https://branchfurniture.com'>
      <BranchLogo />
    </ATag>
  )
}

const TNLogoLink = () => {

  const ATag = styled.a`
  position: relative;
  bottom: -1px;
  `
  return (
    <ATag target='_blank' href='https://tn.com'>
      <TNLogo />
    </ATag>
  )
}

const RenderBody = () => (
    <>
        <Helmet
            title='Jeff Wells — Bio'
            titleTemplate='Jeff Wells — Bio'
            meta={[
                {
                    name: `description`,
                    content: "Entrepreneur and CTO",
                },
                {
                    property: `og:title`,
                    content: 'Jeff Wells — Bio',
                },
                {
                    property: `og:description`,
                    content: "Entrepreneur and CTO",
                },
            ]}
        />
        <Hero>
            <>
              <h3>Hi. I'm Jeff Wells.</h3>
              <h3>I’m founder and CTO of <MiloLogoLink />. We are a pet healthcare startup, building customer-centric veterinary clinics powered by our own practice management software. We recently opened <a style={{textDecoration: 'none', color: 'magenta'}} target='_blank' href='https://g.page/milobyvcgWBG?share' target='_blank'>our flagship hospital in Brooklyn</a>.</h3>
              <h3>I also built <DeepserveIconLink /> — a cloud hosting platform for deep learning models.</h3>
              <h3>I’m an angel investor in <BranchLogoLink />.</h3>
              <h3>Previously I was an early employee and executive at <TNLogoLink />. We scaled to $200M in revenue in 5 years and sold to Serta-Simmons. We bootstrapped the company with no outside funding.</h3>
              <div style={{marginTop: '150px', marginBottom: '50px', display: 'flex', width: '100%', justifyContent: 'center'}}>
                <img src={JeffWells} style={{borderRadius: '50%', height: '200px'}}/>
              </div>


            </>

        </Hero>

    </>
);

// <h3>I do backend, front end, design, product, and machine learning.</h3>
// <h3>I like to use Rails, React, Gatsby, AWS CloudFormation, PyTorch, FastAI, and Figma.</h3>
// <br />
// <hr />
// <br />
//
//

// <Section>
//     {projects.map((project, i) => (
//         <ProjectCard
//             key={i}
//             category={project.node.project_category}
//             title={project.node.project_title}
//             description={project.node.project_preview_description}
//             thumbnail={project.node.project_preview_thumbnail}
//             uid={project.node._meta.uid}
//         />
//     ))}
//     <WorkAction to={"/work"}>
//         See more work <span>&#8594;</span>
//     </WorkAction>
// </Section>
// <Section>
//     {RichText.render(home.about_title)}
//     <About
//         bio={home.about_bio}
//         socialLinks={home.about_links}
//     />
// </Section>

export default () => {
    //Required check for no data being returned
    // const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
    // const projects = data.prismic.allProjects.edges;
    // const meta = data.site.siteMetadata;

    // if (!doc || !projects) return null;

    return (
        <Layout>
            <RenderBody />
        </Layout>
    )
}

RenderBody.propTypes = {
    home: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
};
//
// export const query = graphql`
//     {
//         prismic {
//             allHomepages {
//                 edges {
//                     node {
//                         hero_title
//                         hero_button_text
//                         hero_button_link {
//                             ... on PRISMIC__ExternalLink {
//                                 _linkType
//                                 url
//                             }
//                         }
//                         content
//                         about_title
//                         about_bio
//                         about_links {
//                             about_link
//                         }
//                     }
//                 }
//             }
//             allProjects {
//                 edges {
//                     node {
//                         project_title
//                         project_preview_description
//                         project_preview_thumbnail
//                         project_category
//                         project_post_date
//                         _meta {
//                             uid
//                         }
//                     }
//                 }
//             }
//         }
//         site {
//             siteMetadata {
//                 title
//                 description
//                 author
//             }
//         }
//     }
// `
